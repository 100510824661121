.custom-overflow {
  overflow-x: scroll;
}
.custom-overflow::webkit-track {
  width: 0;
  display: none;
}
.custom-overflow::-webkit-scrollbar-thumb {
    width: 0;
    display: none;
  }
