@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --primary: #23408f;
    --secondary: #01aeef;
    --tertiary: #abc8ed;
    --floor: #e5e7eb;
    --dark: #222d35;
  }

  li,
  Link,
  a {
    @apply cursor-pointer text-dark;
  }
  .default-input {
    @apply text-sm placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-secondary focus:text-gray-600;
  }
  .form-label {
    @apply font-semibold text-[15px];
  }
  .text-input {
    @apply mt-1 block w-full px-2 py-[4px] border-gray-400 rounded border-[1.5px] focus:border-blue-400 hover:border-blue-400 text-gray-900  focus:outline-none  bg-transparent mb-2 hover:duration-300;
  }
  .account-form-input {
    @apply mt-1 block w-full px-4 py-[8px] border-gray-200 rounded-xl border-[1px] text-gray-900 text-[14px]  bg-transparent mb-2;
  }
}
