@font-face {
  font-family: "Gordita-Thin";
  src: local("Gordita-Thin"), url("./fonts/Gordita-Thin.woff") format("woff");
}
@font-face {
  font-family: "Gordita-Regular";
  src: local("Gordita-Regular"),
    url("./fonts/Gordita-Regular.woff") format("woff");
}

@font-face {
  font-family: "Gordita-Bold";
  src: local("Gordita-Bold"), url("./fonts/Gordita-Bold.woff") format("woff");
}

* {
  box-sizing: border-box;
}

body {
  font-family: "Gordita-Regular", sans-serif !important;
  font-style: normal !important;
  font-weight: normal !important;
  list-height: 32px !important;
  -webkit-font-smoothing: antialiased;
}

.sidebar {
  box-shadow: rgb(113 122 131 / 11%) 0px 7px 30px 0px;
}
.nav-item,
.navbar {
  z-index: 10000;
}
@media screen and (max-width: 800px) {
  .sidebar {
    z-index: 10000000;
  }
}

.e-dlg-center-center,
.e-quick-popup-wrapper.e-device {
  z-index: 1000000 !important;
}

body::-webkit-scrollbar {
  width: 6px;
}
body::-webkit-scrollbar-thumb {
  background-color: rgb(216, 216, 216);
  border-radius: 40px;
}
body::-webkit-scrollbar-track {
  background-color: transparent;
}
